.Map {
  flex-grow: 1;
  height: 100%;
}
.Map .gm-bundled-control-on-bottom {
  display: none;
}
.Map-location {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  left: 10px;
  position: absolute;
  top: 10px;
}

@media (min-width: 768px) {
  .Map .gm-bundled-control-on-bottom {
    display: block;
  }
  .Map-location {
    left: auto;
    right: 10px;
  }
}
