.Photos {
  margin-top: 50px;
  padding: 10px;
}

.Photos .MuiButtonBase-root {
  min-width: 0;
}

.Photos-header {
  font-size: 18px;
  font-weight: 400;
  margin: 10px 0;
}
.Photos-dayImages {
  display: flex;
  flex-flow: row wrap;
}
.Photos-image,
.Photos-noImage {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  width: 60px;
}
.Photos-image:focus,
.Photos-noImage:focus {
  outline: none;
}
.Photos-image:focus::after,
.Photos-noImage:focus::after {
  border: 2px solid #1967d2;
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.Photos-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60px;
}
.Photos-image--old {
  filter: grayscale(100%);
  opacity: 0.8;
}
.Photos-noImage {
  border: 3px dashed #ccc;
  padding-bottom: 52px;
}

@media (min-width: 768px) {
  .Photos {
    margin: 0;
    padding: 10px;
  }
}