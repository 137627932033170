.TripList {
  margin-top: 50px;
}
.TripList-yearHeader {
  background-color: #fff;
  border-bottom: 2px solid #000;
  font-size: 20px;
  font-weight: 500;
  margin: 0 6px;
  padding: 10px 8px 4px;
  position: sticky;
  top: 50px;
  z-index: 10;
}
.TripList-yearTrips {
  padding: 0 10px;
}

@media (min-width: 768px) {
  .TripList {
    margin: 0;
  }
  .TripList-yearHeader {
    top: 0;
  }
}
