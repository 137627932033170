.AboutDialog-title.MuiDialogTitle-root {
  align-items: center;
  background-color: #1D428A;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
}
.AboutDialog-title .MuiSvgIcon-root {
  fill: #FFFFFF;
}
.AboutDialog-titleHeader {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}
