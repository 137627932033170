.NeighborhoodButton.MuiButton-root {
  align-items: flex-start;
  border-radius: 0;
  color: #000;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin: 4px 0;
  position: relative;
  scroll-margin: 70px;
  text-transform: none;
  width: 100%;
}
.NeighborhoodButton.MuiButton-root + .NeighborhoodButton.MuiButton-root::before {
  border-top: 1px solid #DDD;
  content: '';
  position: absolute;
  left: 8px;
  right: 8px;
  top: -4px;
}
.NeighborhoodButton-name {
  font-size: 18px;
  font-weight: 400;
}
.NeighborhoodButton-description {
  font-size: 12px;
  font-weight: 500;
  margin-top: -6px;
}
.NeighborhoodButton--condensed .NeighborhoodButton-description {
  font-weight: 400;
}
.NeighborhoodButton-stats {
  display: flex;
  flex-flow: column wrap;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.NeighborhoodButton-stat {
  color: #808080;
  margin-top: -4px;
}
.NeighborhoodButton-stat .MuiSvgIcon-root {
  height: 14px;
  position: relative;
  margin-right: 2px;
  top: -1px;
  vertical-align: middle;
  width: 14px;
}
.NeighborhoodButton-stat.NeighborhoodButton-statStations .MuiSvgIcon-root {
  margin-right: 5px;
}
.NeighborhoodButton-stat.NeighborhoodButton-statPhotos .MuiSvgIcon-root {
  height: 12px;
  margin: 0 3px 0 1px;
  width: 12px;
}
.NeighborhoodButton-stat.NeighborhoodButton-statProgress .MuiSvgIcon-root {
  height: 12px;
  left: 0.5px;
  margin-right: 5px;
  width: 14px;
}
.NeighborhoodButton-complete {
  color: #4db546;
}
.NeighborhoodButton-stationsRemaining {
  color: #4b96f3;
}

@media (min-width: 768px) {
  .NeighborhoodButton.MuiButton-root::after {
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .NeighborhoodButton.MuiButton-root.NeighborhoodButton:active::after {
    border-right: none;
  }
}
