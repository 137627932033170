.TimeTravel::before {
  border: 4px solid #1967d2;
  border-radius: 12px;
  bottom: 3px;
  content: '';
  left: 3px;
  pointer-events: none;
  position: absolute;
  right: 3px;
  top: 3px;
}
.TimeTravel-pane {
  background-color: #fff;
  border-radius: 6px;
  bottom: 161px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  left: 10px;
  padding: 0 20px;
  position: absolute;
  right: 10px;
}
.TimeTravel-currentMonth {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 12px;
  color: #FFF;
  font-size: 32px;
  left: 50%;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  top: -64px;
}

@media (min-width: 768px) {
  .TimeTravel-pane {
    bottom: 10px;
    right: 64px;
  }
}
