.Neighborhoods {
  max-height: 240px;
  overflow-y: auto;
  padding: 0 10px 10px;
}

@media (min-width: 768px) {
  .Neighborhoods {
    margin: 0;
    max-height: none;
  }
}
