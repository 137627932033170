.AreaDetail-primaryPhoto {
  background-color: #ccc;
  background-position: 50% 25%;
  background-size: cover;
  height: 160px;
}

.AreaDetail-details {
  padding: 20px;
}
.AreaDetail-title {
  font-size: 28px;
  font-weight: 400;
  margin: 0;
}
.AreaDetail-title--long {
  font-size: 22px;
}
.AreaDetail-subtitle {
  font-size: 16px;
  font-weight: 500;
  margin: 2px 0 0;
}
.AreaDetail-subtitle--status {
  color: #777;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.AreaDetail-statistic {
  font-size: 14px;
  margin-top: 2px;
}
.AreaDetail-statisticInactive {
  color: #777;
}
.AreaDetail-statisticVisited {
  color: #4db546;
}
.AreaDetail-statisticUnvisited {
  color: #4b96f3;
}

.AreaDetail-status {
  display: flex;
  justify-content: center;
}
.AreaDetail-statusItem {
  font-size: 12px;
  margin-bottom: 0;
  padding: 0 20px;
  position: relative;
  text-align: center;
}
.AreaDetail-statusItem + .AreaDetail-statusItem::before {
  border-left: 1px solid #ccc;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  top: 10px;
}
.AreaDetail-statusNumber {
  display: block;
  font-size: 36px;
}

.AreaDetail-photos {
  display: flex;
  flex-flow: row nowrap;
  overflow-x: auto;
  padding: 0 10px 20px 20px;
  max-width: 100%;
  width: 100%;
}
.AreaDetail-photo {
  background-color: #ddd;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  flex-shrink: 0;
  height: 82px;
  margin-right: 10px;
  position: relative;
  width: 82px;
}
.AreaDetail-photo:focus {
  outline: none;
}
.AreaDetail-photo:focus::after {
  border: 2px solid #1967d2;
  border-radius: 4px;
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

@media (min-width: 768px) {
  .AreaDetail-primaryPhoto {
    background-position: 50% 25%;
    height: 250px;
  }

  .AreaDetail-photos {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .AreaDetail-photo {
    margin-bottom: 10px;
    margin-right: 0;
    padding-top: 48%;
    width: 48%;
  }
}
