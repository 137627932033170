* {
  box-sizing: border-box;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.App {
  bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.App-loading {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
