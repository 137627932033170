.InfoPane {
  background-color: #fff;
  border-radius: 6px;
  bottom: 10px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  display: flex;
  flex-flow: column nowrap;
  left: 10px;
  position: absolute;
  right: 10px;
}
.InfoPane-header {
  align-items: center;
  background-color: #1D428A;
  border-radius: 6px 6px 0 0;
  color: #fff;
  display: flex;
  flex-flow: row wrap;
  flex-shrink: 0;
  height: 50px;
  justify-content: space-between;
  overflow: hidden;
  padding-left: 10px;
}
.InfoPane-header--inDrawer {
  border-radius: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}
.InfoPane-header > h1 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}
.InfoPane-header .MuiButton-textPrimary,
.InfoPane-header .MuiIconButton-root {
  color: #FFF;
}
.InfoPane-content {
  border-radius: 0 0 6px 6px;
  flex-grow: 1;
  overflow: auto;
}
.InfoPane-contentMain {
  padding: 0 10px;
}
.InfoPane-menu::before {
  border-top: 1px solid #DDD;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

h2.InfoPane-contentHeader {
  font-size: 22px;
  font-weight: normal;
  margin: 0;
}
h6.InfoPane-contentHeader {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 10px;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .InfoPane {
    bottom: auto;
    max-height: 95%;
    right: auto;
    top: 10px;
    width: 320px;
  }
  .InfoPane--scrollable {
    bottom: 10px;
  }
}
