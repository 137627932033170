.TripButton.MuiButton-root {
  align-items: flex-start;
  border-radius: 0;
  color: #000;
  flex-flow: column nowrap;
  justify-content: flex-start;
  margin: 4px 0;
  position: relative;
  scroll-margin: 70px;
  text-transform: none;
  width: 100%;
}
.TripButton.TripButton--condensed.MuiButton-root {
  padding: 2px 4px;
}
.TripButton.MuiButton-root + .TripButton.MuiButton-root::before {
  border-top: 1px solid #DDD;
  content: '';
  position: absolute;
  left: 8px;
  right: 8px;
  top: -4px;
}
.TripButton.MuiButton-root::after {
  bottom: 0;
  content: '';
  position: absolute;
  left: -4px;
  top: 0;
}
.TripButton.MuiButton-root:active::after {
  border-right: 4px solid #1967d2;
}
.TripButton.MuiButton-root.TripButton--active::after {
  border-right: 4px solid #1967d2;
}
.TripButton-date {
  font-size: 18px;
  font-weight: 400;
}
.TripButton--condensed .TripButton-date {
  font-size: 12px;
  font-weight: 500;
}
.TripButton-description {
  font-size: 12px;
  font-weight: 500;
  margin-top: -6px;
}
.TripButton--condensed .TripButton-description {
  font-weight: 400;
}
.TripButton-stats {
  display: flex;
  flex-flow: column wrap;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}
.TripButton-stat {
  color: #808080;
  margin-top: -4px;
}
.TripButton-stat .MuiSvgIcon-root {
  height: 14px;
  position: relative;
  margin-right: 2px;
  top: -1px;
  vertical-align: middle;
  width: 14px;
}
.TripButton-stat.TripButton-statStations .MuiSvgIcon-root {
  margin-right: 5px;
}
.TripButton-stat.TripButton-statPhotos .MuiSvgIcon-root {
  height: 12px;
  margin: 0 3px 0 1px;
  width: 12px;
}
.TripButton-stat.TripButton-statDistance .MuiSvgIcon-root {
  left: 0.5px;
  margin-right: 5px;
}
.TripButton-stationsNew {
  color: #4db546;
}

@media (min-width: 768px) {
  .TripButton.MuiButton-root::after {
    transition: border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .TripButton.MuiButton-root.TripButton:active::after {
    border-right: none;
  }
}
